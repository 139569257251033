<template>
  <div class="body">
    <!-- 挂号缴费 -->
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-choose-jzk-over">
        <div class="yyqr-choose-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-choose-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooseJZPerson(item, index)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-choose-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-choose-add" @click="addPatient">
        <van-icon name="plus" />
        添加就诊人
      </div>
    </van-popup>
    <van-tabs
      v-model="activeName"
      title-active-color="#00D48B "
      color="#00D48B"
      swipeable
      @change="handleTabChange"
    >
      <van-tab title="未缴费" name="a">
        <div
          class="content-list"
          v-for="(item, index) in nopaylist"
          :key="index"
        >
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">预约科室：</span
            >{{ item.bizDeptName.replace(item.bizDoctorName, "") }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">预约医生：</span
            >{{ item.bizDoctorName }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊时间：</span
            >{{ item.dutyDate }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">费用：</span
            ><span style="color: #ee0a24">￥{{ item.clinicFee / 100 }}</span>
          </div>
          <div class="van-hairline--bottom"></div>
          <div class="notice-tips">
            <span class="notice-text">就诊当天有效，否则自动取消</span>
            <span class="gotoPay" @click="gotoPay(item)">去支付</span>
            <span class="gotoCancle" @click="handleCancle(item)">取消</span>
          </div>
        </div>
        <div class="baogao3" v-if="nopaylist.length == 0">
          <img src="@/assets/img/ucenter/21.png" alt="" />
        </div>
      </van-tab>
      <van-tab title="已缴费" name="b">
        <div class="content-list" v-for="(item, index) in paylist" :key="index">
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">预约科室：</span
            >{{ item.bizDeptName.replace(item.bizDoctorName, "") }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">预约医生：</span
            >{{ item.bizDoctorName }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊序号：</span>{{ item.signNo }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊状态：</span
            >{{ item.clinicStatus == 0 ? "未就诊" : "已就诊" }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">就诊时间：</span
            >{{ item.dutyDate }}
          </div>
          <div class="baseinfo-item">
            <span class="baseinfo-item-label">费用：</span
            ><span style="color: #ee0a24">￥{{ item.clinicFee / 100 }}</span>
          </div>
          <div class="van-hairline--bottom"></div>
          <div class="order-btn">
            <span class="gotoCancle">{{
              regPayStatus[item.regPayStatus]
            }}</span>
            <div>
              <van-button
                plain
                size="small"
                type="danger"
                v-if="item.allowRefundStatus == 1"
                @click="handleRefund(item)"
                >退款
              </van-button>
              <van-button
                plain
                size="small"
                type="primary"
                @click="evaluate(item)"
                >写评价</van-button
              >
            </div>
          </div>
        </div>
        <div class="baogao3" v-if="paylist.length == 0">
          <img src="@/assets/img/ucenter/21.png" alt="" />
        </div>
      </van-tab>
    </van-tabs>
    <van-dialog
      class="dialog"
      v-model="overflowShow"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <template #title>
        <h3 class="dialog-title">提示</h3>
      </template>
      <p>
        {{ content }}
      </p>
      <van-button class="cancle-btn" @click="overflowShow = false"
        >取消</van-button
      >
      <van-button
        type="primary"
        class="confirm-btn"
        @click="gotoCancle(cancleItem, status)"
        >确认</van-button
      >
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: "appointPayment",
  data() {
    return {
      showPopup: false, // 弹出层
      list: [],
      personId: "",
      chooseItem: "",
      paylist: [],
      nopaylist: [],
      activeName: "",
      overflowShow: false,
      cancleItem: "",
      content: "",
      status: "",
      regPayStatus: {
        0: "未支付",
        1: "已支付",
        2: "已退款",
      },
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooseItem) {
        this.showPopup = true;
        return;
      }
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },
    chooseJZPerson(item, index) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      sessionStorage.setItem("CurrentJzrIndex", index);
      this.chooseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.getDataList1();
      this.getDataList2();
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status === 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooseItem = this.list[i];
                  }
                }
                if (!this.chooseItem.deafult) {
                  this.chooseItem.deafult = true;
                  this.list[0].deafult = true;
                }
                sessionStorage.setItem(
                  "CurrentJZPerson",
                  JSON.stringify(this.chooseItem)
                );
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooseItem = CurrentJZPerson;
            }
            this.getDataList1();
            this.getDataList2();
            this.isHasPatient();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    // 未支付
    getDataList1() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      // postData.patid = "0010939496";
      postData.patid = this.chooseItem.patId;
      postData.status = false;
      this.$http
        .get("/api/wlptappointment/paymentList", postData)
        .then((result) => {
          this.nopaylist = result.data;
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 已支付
    getDataList2() {
      let postData = {};
      postData.healthId = this.chooseItem.healthId;
      // postData.patid = "0010939496";
      postData.patid = this.chooseItem.patId;
      postData.status = true;
      this.$http
        .get("/api/wlptappointment/paymentList", postData)
        .then((result) => {
          this.paylist = result.data;
        });
    },
    // 去支付
    gotoPay(item) {
      this.$router.push({
        path: "/appointWxPayPage",
        query: {
          id: item.bizOrderLineNo,
          price: item.clinicFee,
          type: 1,
        },
      });
    },
    /**
     * 取消支付，申请退款接口
     * @param item
     */
    gotoCancle(item, index) {
      if (index == 0) {
        //未缴费取消支付，号源解除锁定
        let postData = {
          hisorderid: item.bizOrderLineNo,
          type: 1,
        };
        this.$http
          .get("/api/wlptappointment/eh3107", postData)
          .then(() => {
            Toast({ message: "取消成功" });
            this.getDataList1();
          })
          .catch(() => {
            Toast.fail({
              message: "取消失败",
            });
            this.getDataList1();
          });
      } else if (index == 1) {
        if (item.regSourceType == 2) {
          // regSourceType为2，代表公众号
          let postData = {
            id: item.bizOrderLineNo,
          };
          this.$http
            .post("/api/wlptappointment/eh3111", postData)
            .then(() => {
              Toast({ message: "申请退费成功" });
              this.getDataList2();
            })
            .catch(() => {
              Toast.fail({
                message: "申请退费失败",
              });
              this.getDataList2();
            });
        } else {
          // regSourceType不为2，代表非公众号
          this.$http
            .post("/api/wlptappointment/eh3111today", item)
            .then(() => {
              Toast({ message: "申请退费成功" });
              this.getDataList2();
            })
            .catch(() => {
              Toast.fail({
                message: "申请退费失败",
              });
              this.getDataList2();
            });
        }
      }
      this.overflowShow = false;
    },
    handleRefund(item) {
      this.content = "是否申请退费？";
      this.overflowShow = true;
      this.cancleItem = item;
      this.status = 1;
    },
    handleCancle(item) {
      this.content = "是否要取消预约？";
      this.overflowShow = true;
      this.cancleItem = item;
      this.status = 0;
    },
    evaluate(item) {
      // 跳转写评价页面
      window.sessionStorage.setItem("recordsList", JSON.stringify(item));
      this.$router.push({
        path: "/Evaluate",
        query: {
          id: this.chooseItem.patId,
          name: this.chooseItem.name,
        },
      });
    },
    handleTabChange(name, title) {
      this.$router.replace({
        query: {
          activeName: name,
        },
      });
    },
  },
  mounted() {
    this.getList();
    this.activeName = this.$route.query.activeName;
  },
};
</script>

<style scoped>
.body {
  background-color: #f8f9f9;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  left: -2px;
}

.yyqr-choose-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}

.yyqr-choose-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}

.yyqr-choose-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}

.yyqr-choose-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}

.yyqr-choose-jzk .checked {
  color: #03d28c;
}

.yyqr-choose-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}

.yyqr-choose-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-choose-add .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}

.content-list {
  width: 9rem;
  margin: 10px auto;
  text-align: left;
  padding: 5px;
  padding-bottom: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}

.baseinfo-item {
  font-size: 0.4rem;
  line-height: 26px;
}

.baseinfo-item-label {
  width: 2.5rem;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}

.notice-tips {
  padding: 5px;
}

.notice-tips .notice-text {
  font-size: 0.3rem;
  color: #1d9ecf;
  display: inline-block;
  text-overflow: ellipsis;
  width: 4.7rem;
  overflow: hidden;
  white-space: nowrap;
}

.notice-tips .gotoCancle {
  font-size: 0.35rem;
  color: #333333;
  float: right;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.notice-tips .gotoPay {
  float: right;
  color: #03d28c;
  font-size: 0.35rem;
}

.notice-tips .cancled {
  display: inline-block;
  float: right;
}

/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}

.order-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.order-btn .van-button {
  border-radius: 5px;
  margin-left: 0.2rem;
}
/deep/ .van-tab--active {
  background-color: #f5f5f5;
}

.dialog {
  padding: 0.5rem;
  padding-top: 0;
  line-height: 0.8rem;
  box-sizing: border-box;
}

.dialog div {
  text-align: left;
}

.confirm-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-top: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#57fdc4, #00d48b);
  color: #fff;
}

.cancle-btn {
  width: 40%;
  border-radius: 25px;
  margin: 5px;
  margin-bottom: 25px;
  font-size: 0.45rem;
  border: none;
  background-color: #fff;
  background-image: linear-gradient(#d2d2d2, #b9b9b9);
  color: #fff;
}

.dialog-title {
  margin-bottom: 0.3rem;
}
.gotoCancle {
  font-size: 0.35rem;
  display: flex;
  align-items: center;
  color: #333333;
  float: left;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
</style>
